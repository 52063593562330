<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pa-4 wrap justify-center>
      <v-flex text-center>
        <span style="font-size:20px;font-weight:500">{{name}}</span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex pa-4>
        <v-card width="900" min-height="65vh">
          <v-layout pa-4 wrap justify-center>
            <v-flex pl-8>
              <span style="font-size:20px;font-weight:400">Documents</span>
            </v-flex>
          </v-layout>
          <v-layout v-if="!items.length<1" px-2 pb-8 pl-md-12 wrap>
            <v-flex xs12 sm6 md4 id="month_container">
              <v-list>
                <v-list-item-group v-model="item" color="#00A972">
                  <v-layout v-for="(item, i) in items" :key="i">
                    <v-flex text-uppercase>
                      <v-list-item @click="loadDocs(item)">
                        <v-list-item-content>
                          <span style="font-size:14px;font-weight:400">{{item.categoryname}}</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-list-item-group>
              </v-list>
            </v-flex>
            <v-divider class="mx-auto" inset vertical></v-divider>
            <v-flex xs12 sm6 md7 id="month_desc_container">
              <v-layout wrap pl-4>
                <v-flex xs12 sm6 md6 text-uppercase>
                  <span style="font-size:14px;font-weight:400;color:#848484;">{{init_cat}}</span>
                </v-flex>
              </v-layout>
              <v-layout pa-4 wrap>
                <v-flex xs12 sm6 md4 v-for="(item,i) in docs" :key="i" text-center>
                  <a :href="baseURL+'/u/'+item.filename" download target="_blank">
                    <v-img src="../../assets/documents/1.png" height="150px" contain></v-img>
                    <span style="font-size:14px;font-weight:500;color:#000">{{item.name}}</span>
                  </a>
                  <br />
                  <v-btn
                    small
                    :ripple="false"
                    dark
                    color="red"
                    width="100"
                    @click="deleteDocument(item)"
                  >Delete</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-else wrap justify-center>
            <v-flex text-center>
              <span style="font-size:20px;font-weight:400">No Documents</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="addDocument" wrap justify-center>
      <v-flex>
        <v-card>
          <v-layout pa-2 px-2 wrap justify-center>
            <v-flex xs12 sm12 md3>
              <v-combobox
                :autocomplete="false"
                v-model="category"
                :items="categories"
                label="Category"
                color="#26af82"
                dense
                required
              ></v-combobox>
            </v-flex>
          </v-layout>
          <v-layout py-4 pb-4 px-2 wrap justify-center>
            <v-flex xs12 sm12 md3>
              <input ref="onFileChanged" id="newDoc" type="file" @change="onFileChanged" required />
            </v-flex>
          </v-layout>
          <v-layout py-4 pb-4 px-2 wrap justify-center>
            <v-flex xs12 sm12 md3>
              <v-text-field
                ref="Caption"
                v-model="caption"
                label="Caption"
                color="#26af82"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout pb-4 px-2 wrap justify-center>
            <v-flex xs12 sm12 md3 text-center>
              <v-btn small :ripple="false" width="220" @click="addItem" dark color="#26af82">Submit</v-btn>
            </v-flex>
            <v-flex xs12 sm12 md3 text-center>
              <v-btn small :ripple="false" width="220" @click="cancelInput" dark color="red">Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex py-4 xs12 sm12 md12 text-center>
        <v-btn
          v-if="!addDocument"
          small
          :ripple="false"
          dark
          color="#26af82"
          width="200"
          @click="addDocument=true"
        >Add New</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["name", "id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      item: 0,
      category: null,
      items: [],
      docs: [],
      categories: [],
      init_cat: null,
      init_doc: null,
      addDocument: false,
      document: null,
      caption: null,
      progressId: null
    };
  },
  beforeMount() {
    this.getData();
    this.getCategory();
  },
  methods: {
    deleteDocument(item) {
      this.appLoading = true;
      var data = {};
      data["id"] = item._id;
      axios({
        method: "POST",
        url: "/Document/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.items = [];
          this.init_cat = null;
          this.getData();
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    cancelInput() {
      this.addDocument = false;
      this.category = null;
      this.progressId = null;
    },
    addItem() {
      let formData = new FormData();
      formData.append("name", this.caption);
      formData.append("category", this.category);
      formData.append("projectid", this.id);
      formData.append("doc", this.document);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/Document/add",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.document = null;
            this.category = null;
            this.caption = null;
            this.addDocument = false;
            this.getData();
            document.getElementById("newDoc").value = "";
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },

    onFileChanged(event) {
      if (event.target.files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        document.getElementById("newDoc").value = "";
        return;
      } else {
        this.document = event.target.files[0];
        this.url = URL.createObjectURL(this.document);
      }
    },
    loadDocs(item) {
      this.init_cat = item.categoryname;
      this.docs = item.data;
    },
    getData() {
      this.appLoading = true;
      var data = {};
      data["projectid"] = this.id;
      axios({
        method: "GET",
        url: "/Document/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: data
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.data.length > 0) {
            this.items = response.data.data;
            this.docs = response.data.data[0].data;
            this.init_cat = this.items[0].categoryname;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable 1";
          this.showSnackBar = true;
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/Document/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          for (var i = 0; i < response.data.data.length; i++) {
            this.categories.push(response.data.data[i].name);
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style scoped>
#month_container {
  max-height: 300px;
  overflow-y: auto;
}
#month_desc_container {
  max-height: 300px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 5px;
}
</style>